import merge from 'webpack-merge';

import Stage from '../classes/stage.js';
import CameraPreset from '../enums/cameraPreset.js';
import RenderType from '../enums/renderType.js';


const DEFAULTS = {
    debug: false,
    debugOptions: {
        showAxes: true,
        showGrid: true
    },
    renderType: RenderType.WEBGL2,
    isAnimated: true,
    stage: {
        cameraPreset: CameraPreset.FRONT
    }
};

export default class Visualization {
    constructor(_settings={}) {
        this.settings = merge(DEFAULTS, _settings);

        this.isReadyForManipulation = false;
        this.canvasContainerElement = document.getElementById('canvasContainer');

        this.isFirstRender = true;

        this.stage = new Stage(this.canvasContainerElement, this.settings);

        if(this.settings.debug) {
            this.debugElement = document.createElement('div');
            this.debugElement.id = 'canvasDebug';

            this.canvasContainerElement.append(this.debugElement);
        }

        this.performExport = false;
        this.isAnimated = this.settings.isAnimated || false;

        if(this.isAnimated) {
            this.startAnimation();
        } else {
            this.render();
        }
    }

    exportImage() {
        this.performExport = true;
    }

    //call this function when the visualization is ready to animate
    ready() {
        this.isReadyForManipulation = true;
    }

    onFrameExecution() {
        //Implement this function in the lower level class using a Visualization;
    }

    INTERNAL_onFrameExecution() {
        if(this.stage.controls !== null) {
            this.stage.controls.update();
        }
    }

    startAnimation() {
        var renderLoop = () => {
            this.renderLoopId = requestAnimationFrame( renderLoop );
            
            if(this.isReadyForManipulation) {
                this.INTERNAL_onFrameExecution();
                this.onFrameExecution();
            }
            
            this.render();

            if(this.performExport) {
                //TODO: obviously this is not the correct place or code:
                var image = new Image();
                image.id = "pic"
                image.src = this.stage.renderer.domElement.toDataURL();
                document.getElementById('canvasContainer').appendChild(image);
    
                this.performExport = false;
            }
        };

        renderLoop();
    }

    stopAnimation() {
        cancelAnimationFrame(this.renderLoopId);
    }

    render() {
        this.renderInterval = setInterval(() => {
            if(this.isReadyForManipulation) {
                this.stage.renderer.render( this.stage.scene, this.stage.camera );

                if(this.isFirstRender) {
                    this.canvasContainerElement.setAttribute('data-isready', 'true');
                    this.isFirstRender = false;
                }

                clearInterval(this.renderInterval);
            }
        }, 2000);
    }
}