import { CylinderGeometry, MeshBasicMaterial, Mesh } from 'three';
import MaterialObject from '../classes/materialObject.js'

export default class Line extends MaterialObject {

    constructor(radius, length, color, {x = null, y = null, z = null}={x: null, y: null, z: null}, {rx = null, ry = null, rz = null}={rx: null, ry: null, rz: null}) {
        super();
        
        var geometry = new CylinderGeometry( radius, radius, length, 32 );
        var material = new MeshBasicMaterial({ color: color });
        this.obj = new Mesh(geometry, material);

        this.setPosition(x, y, z);
        this.setRotation(rx, ry, rz);

        return this.obj;
    }
}