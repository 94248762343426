import CameraType from './cameraType.js';

export default {
    FRONT: {
        cameraType: CameraType.FREE_RANGE,
        cameraPosition: { x: 0, y: 50, z: 250 },
        cameraRotation: { x: 0, y: 0, z: 0 }
    },
    ORBIT: {
        cameraType: CameraType.ORBIT,
        cameraPosition: { x: 0, y: 300, z: 0 },
        cameraRotation: { x: 1.4, y: -6, z: 0 }
    },
    CUBE_FRONT: {
        cameraType: CameraType.FREE_RANGE,
        cameraPosition: { x: 0, y: 140, z: 250 },
        cameraRotation: { x: -0.4, y: 0, z: 0 }
    },
    CUBE_ANGLE: {
        cameraType: CameraType.ORBIT,
        cameraPosition: { x: 87, y: 203, z: 169 },
        cameraRotation: { x: -0.7, y: 0.36, z: 0.31 },
        cameraFocalPoint: { x: 0, y: 50, z: 0 }
    },
    CUBE_TOP: {
        cameraType: CameraType.FREE_RANGE,
        cameraPosition: { x: 0, y: 250, z: 0 },
        cameraRotation: { x: 11, y: 0, z: 0 }
    },
    SCURVE_FRONT: {
        cameraType: CameraType.FREE_RANGE,
        cameraPosition: { x: 50, y: 50, z: 175 },
        cameraRotation: { x: 0, y: 0, z: 0 }  
    },
    SCALE_FRONT: {
        cameraType: CameraType.FREE_RANGE,
        cameraPosition: { x: 50, y: 50, z: 175 },
        cameraRotation: { x: 0, y: 0, z: 0 }  
    },
    NUMERICALVALUE_FRONT: {
        cameraType: CameraType.FREE_RANGE,
        cameraPosition: { x: 50, y: 50, z: 200 },
        cameraRotation: { x: 0, y: 0, z: 0 }
    },
};