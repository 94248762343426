import { ConeGeometry, MeshBasicMaterial, Mesh } from 'three';
import MaterialObject from '../classes/materialObject.js'

export default class Piramide extends MaterialObject {
    constructor(radius, height, color, {x = null, y = null, z = null}={x:null, y:null, z:null}) {
        super();
        
        var geometry = new ConeGeometry(radius, height, 3 );
        var material = new MeshBasicMaterial( {color: color} );
        this.obj  = new Mesh( geometry, material );
    
        this.setPosition(x, y, z);

        return this.obj;
    }
}