import { Group } from 'three';

import MaterialObject from '../../classes/materialObject.js';
import Line from '../../objects/line.js';

export default class ScaleLines extends MaterialObject {
    constructor(lineSize, lineHeight, lineColor, {x = null, y = null, z = null}={x:null, y:null, z:null},{rx = null, ry = null, rz = null}={rx:null, ry:null, rz:null}) {
        super();
        
        this.obj = new Group();

        var line = new Line(lineSize, lineHeight, lineColor, {x: 50, y: 0}, {rz: Math.PI/2});
        var line2 = new Line(lineSize, lineHeight, lineColor, {x: 50, y: 10}, {rz: Math.PI/2});
        var line3 = new Line(lineSize, lineHeight, lineColor, {x: 50, y: 20}, {rz: Math.PI/2});
        var line4 = new Line(lineSize, lineHeight, lineColor, {x: 50, y: 30}, {rz: Math.PI/2});
        var line5 = new Line(lineSize, lineHeight, lineColor, {x: 50, y: 40}, {rz: Math.PI/2});
        var line6 = new Line(lineSize, lineHeight, lineColor, {x: 50, y: 50}, {rz: Math.PI/2});
        var line7 = new Line(lineSize, lineHeight, lineColor, {x: 50, y: 60}, {rz: Math.PI/2});
        var line8 = new Line(lineSize, lineHeight, lineColor, {x: 50, y: 70}, {rz: Math.PI/2});
        var line9 = new Line(lineSize, lineHeight, lineColor, {x: 50, y: 80}, {rz: Math.PI/2});
        var line10 = new Line(lineSize, lineHeight, lineColor, {x: 50, y: 90}, {rz: Math.PI/2});
        var line11 = new Line(lineSize, lineHeight, lineColor, {x: 50, y: 100}, {rz: Math.PI/2});

        this.obj.add(line);
        this.obj.add(line2);
        this.obj.add(line3);
        this.obj.add(line4);
        this.obj.add(line5);
        this.obj.add(line6);
        this.obj.add(line7);
        this.obj.add(line8);
        this.obj.add(line9);
        this.obj.add(line10);
        this.obj.add(line11);

        this.setPosition(x, y, z);
        this.setRotation(rx, ry, rz);

        //return this.obj;
    }
}