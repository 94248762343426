import { SphereGeometry, MeshBasicMaterial, Mesh } from 'three';
import MaterialObject from '../classes/materialObject.js'

export default class Ball extends MaterialObject {
    constructor(radius, color, {x = null, y = null, z = null}={x:null, y:null, z:null}) {
        super();
        
        var geometry = new SphereGeometry( radius, 32, 32 );
        var material = new MeshBasicMaterial( {color: color } );
        this.obj = new Mesh( geometry, material );
    
        this.setPosition(x, y, z);

        return this.obj;
    }
}