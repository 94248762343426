import { Mesh, Vector3, Geometry, LineBasicMaterial, Line, SphereGeometry, MeshBasicMaterial } from 'three';

import Visualization from '../classes/visualization.js';
import MySCurve from '../objects/compoundObjects/scurve.js';
import ScaleLines from '../objects/compoundObjects/scaleLines.js';
import Ball from '../objects/ball.js';
import Piramide from '../objects/piramide.js';
import PointerType from '../enums/pointerType.js';

import merge from 'webpack-merge';

const DEFAULTS = {
    data: {

    }
};


export default class SCurve extends Visualization {
    constructor(_settings={}) {
        let settings = merge(DEFAULTS, _settings);
        super(settings);

        this.settings = settings;

        this.y = 0;
        this.yDirectionIsUp = true;

        this.scurve = new MySCurve();
        this.stage.scene.add(this.scurve.obj);

        this.scaleLines = new ScaleLines(.1, 100, 0x392930);
        this.stage.scene.add(this.scaleLines.obj);

        if (settings.debug) {
            this.drawYAxisHelper();
            this.drawXAxisHelper();
        }

        this.pointers = new Map();
        
        this.init();
        this.ready();
    }

    init() {
        for (let pointer of this.settings.data.pointers) {
            this.addPointer(pointer);
        }
    }

    addPointer(pointer) {
        if (typeof pointer.percentile.x !== "undefined") {
            let obj = this.getPointForX(pointer.percentile.x, pointer.color, pointer.type);
            this.stage.scene.add(obj);
            this.pointers.set(pointer.name, obj);
        }
        if (typeof pointer.percentile.y !== "undefined") {
            let obj = this.getPointForY(pointer.percentile.y, pointer.color, pointer.type);
            this.stage.scene.add(obj);
            this.pointers.set(pointer.name, obj);
        }
    }

    removePointer(name) {
        this.pointers.remove(name);
    }

    getPointForY(y, color=0x0000ff, type=PointerType.INTERNAL) {
        let x = this.scurve.getXForY(y);

        var point = null;
        switch (type) {
            case PointerType.INTERNAL:
                point = new Ball(2, color, { x: x, y: y });
                break;
            case PointerType.EXTERNAL:
                point = new Piramide(3, 3, color, { x: x, y: y });
                break;
        }

        return point;
    }

    getPointForX(x, color = 0x0000ff, type = PointerType.INTERNAL) {
        let y = this.scurve.getYForX(x);

        var point = null;
        switch (type) {
        case PointerType.INTERNAL:
            point = new Ball(2, color, { x: x, y: y });
            break;
        case PointerType.EXTERNAL:
            point = new Piramide(3, 3, color, { x: x, y: y });
            break;
        }

        return point;
    }

    onFrameExecution() {
        return;

        //testscript to test every possible y value:
        this.drawPointHelper();

        if (this.yDirectionIsUp) {
            this.y += .1;
        } else {
            this.y -= .1;
        }

        this.line.position.y = this.y;

        this.target.position.y = this.y;
        this.target.position.x = this.scurve.getXForY(this.y);

        if(this.y >= 100) {
            this.yDirectionIsUp = false;
        } 

        if(this.y <= 0) {
            this.yDirectionIsUp = true;
        } 
    }

    drawPointHelper() {
        var geometry = new SphereGeometry(2, 32, 32);
        var material = new MeshBasicMaterial({ color: 0xff0000 });
        this.target = new Mesh(geometry, material);
        this.target.position.y = this.y;
        this.target.position.x = this.scurve.getXForY(this.y);
        this.stage.scene.add(this.target);
    }

    drawYAxisHelper() {
        var geometry3 = new Geometry();
        geometry3.vertices.push(new Vector3(0, 0, 0));
        geometry3.vertices.push(new Vector3(0, 100, 0));
        var material3 = new LineBasicMaterial({ color: 0xff0000 });
        this.line3 = new Line(geometry3, material3);
        this.stage.scene.add(this.line3);

        for (let y = 0; y <= 100; y += 10) {
            var geometry4 = new Geometry();
            geometry4.vertices.push(new Vector3(-10, y, 0));
            geometry4.vertices.push(new Vector3(10, y, 0));
            var material4 = new LineBasicMaterial({ color: 0xff0000 });
            this.line4 = new Line(geometry4, material4);
            this.stage.scene.add(this.line4);
        }
    }

    drawXAxisHelper() {
        var geometry = new Geometry();
        geometry.vertices.push(new Vector3(0, this.y, 0));
        geometry.vertices.push(new Vector3(100, this.y, 0));
        var material = new LineBasicMaterial({ color: 0x0000ff });
        this.line = new Line(geometry, material);
        this.stage.scene.add(this.line);
    }
}